<template>
  <meta>
</template>

<script>
let AddedToCartPopup
let id = 0

let LanguageSelectorModal

export default {
  name: 'PopupListener',
  data () {
    return {
      id: id++
    }
  },
  mounted () {
    this.$nuxt.$on('after_add_product_to_cart', this.showConfirmation)
    this.$nuxt.$on('show_language_selector_popup', this.showLanguageSelectorPopup)
  },
  destroyed () {
    this.$nuxt.$off('after_add_product_to_cart', this.showConfirmation)
    this.$nuxt.$off('show_language_selector_popup', this.showLanguageSelectorPopup)
  },
  methods: {
    async showConfirmation (config) {
      // TODO: This is a hack to prevent the popup showing twice.
      if (this.id !== id - 1) {
        return
      }
      if (config?.silent) {
        return
      }

      if (!AddedToCartPopup && !this.freeGiftPopupOpen) {
        AddedToCartPopup = await import('../added-to-cart-popup/AddedToCartPopup.vue')
      }

      this.$modal.show(AddedToCartPopup.default, {
      }, {
        height: 'auto',
        scrollable: true,
        classes: ['added-to-cart-popup'],
        adaptive: true,
        transition: 'slide-in-top-fade'
      })
    },

    async showLanguageSelectorPopup () {
      if (!LanguageSelectorModal) {
        LanguageSelectorModal = await import('../languageSelectorPopup/LanguageSelectorPopup.vue')
      }

      this.$modal.show(LanguageSelectorModal.default, {
      }, {
        adaptive: true,
        height: 'auto',
        classes: ['language-selector-modal'],
        transition: 'slide-in-top-fade'
      })
    }
  }
}
</script>
