<template>
  <main id="main-content" :class="classes">
    <div class="sticky-toasts">
      <div id="triggerbee_toast_sticky" class="hide-on-pdp" />
      <Toast
        v-for="(stickyToast, i) in stickyToasts"
        :class="{
          'hide-on-pdp':
            stickyToast.component === 'GlobalToast' && stickyToast.hiddenOnPDP,
        }"
        :key="`${stickyToast._uid}-${i}`"
        :cookie-id="stickyToast._uid"
        v-editable="editableData"
        v-bind="stickyToast"
      />
    </div>
    <div id="triggerbee_toast" class="hide-on-pdp" />
    <Toast
      v-for="toast in toasts"
      :class="{
        'hide-on-pdp': toast.component === 'GlobalToast' && toast.hiddenOnPDP,
      }"
      :key="toast._uid"
      :cookie-id="toast._uid"
      v-editable="editableData"
      v-bind="toast"
    />
    <div class="page">
      <component
        :is="component"
        :id="id"
        :url="url"
        :language-code="currentLanguageCode"
        :market-id="market.market"
        :pricelist-id="pricelist.pricelist"
        :data="data"
      />
    </div>
    <PopupListener />
  </main>
</template>

<script>
/**
 * This _.vue file is used to conditionally render the correct component
 * depending on URLs that are entered in external databases such as Centra
 * and Storyblok
 */
import { mapGetters } from 'vuex'
import PopupListener from '~/components/added-to-cart-popup/PopupListener'

export default {
  name: 'WildcardPage',
  components: { PopupListener },
  serverPrefetch () {
    // This is allowed if we're behind fastly
    if (this.$config.pageResponseCacheTtl) {
      this.$ssrContext.res.setHeader(
        'cache-control',
        `public, max-age=${this.$config.pageResponseCacheTtl}, stale-while-revalidate=600`
      )
    }
  },
  asyncData ({ app, store, error }) {
    // No match means 404 (the route does not exist anywhere and is caught here)
    const route = app.context.route.path
    return store.dispatch('routes/lookupRoute', route).then((result) => {
      if (result === undefined) {
        error({ statusCode: 404, message: 'Page Not Found' })
      } else {
        return result
      }
    })
  },
  data () {
    return {
      stickyToasts: [],
      toasts: [],
    }
  },
  computed: {
    ...mapGetters({
      getStoryById: 'storyblok/getStoryById',
      getProductById: 'centra-product/getProductById',
      getCurrentCountryCode: 'frontend/currentCountryCode',
      currentLanguageCode: 'frontend/currentLanguageCode',
      lineProduct: 'centra-cart/lineProduct',
    }),
    globalToasts () {
      const globalToasts = this.$store.getters['storyblok/globalToasts']
      if (globalToasts?.data?.length) {
        return globalToasts.data.filter(it => !this.$cookies.get(it._uid))
      }
      return []
    },
    pageToasts () {
      let toasts = []
      if (this.component === 'storyblok') {
        const page = this.getStoryById(this.id)
        if (page && page?.content?.toasts) {
          // Look for toasts related to the current locale
          // Older toasts created before "countries" was added should also be in the list
          toasts = page.content.toasts.filter(
            it =>
              it.countries?.includes(this.getCurrentCountryCode) ||
              !it.countries
          )
          if (!toasts.length) {
            const fallback = '_all_countries'
            toasts = page.content.toasts.filter(it =>
              it.countries?.includes(fallback)
            )
          }
          return toasts.filter(it => !this.$cookies.get(it._uid))
        }
      }
      return toasts
    },
    classes () {
      if (this.component === 'storyblok') {
        const page = this.getStoryById(this.id)
        if (!page) {
          throw new Error(
            `page data with id ${this.id} could not be found, please verify you have matching storyblock tokens in backend and frontend`
          )
        }

        const classes = [
          `block-spacing mbs--${page?.content?.blockSpacingMobile}`,
          `block-spacing dbs--${page?.content?.blockSpacingDesktop}`,
          `bg-color--${page?.content?.backgroundColor}`,
        ]
        if (page?.content?.hideFooter === '1') {
          classes.push('hide-footer')
        }
        if (page?.content?.hideStory === '1') {
          classes.push('hide-story')
        }
        if (page?.content?.hideUsp === '1') {
          classes.push('hide-usp')
        }
        if (page?.content?.hideFooterMenu === '1') {
          classes.push('hide-footer-menu')
        }
        return classes
      }
      return []
    },
    editableData () {
      return this.data ?? {}
    },
  },
  created () {
    this.initToasts()
    this.initPage()
  },
  mounted () {
    this.$activateGoogleOptimize()
    this.$sysend.on('checkoutSuccess', () => {
      window.location.reload(true) // forced reload
    })
  },
  beforeDestroy () {
    this.$sysend.off('checkoutSuccess')
    this.$sysend.off('cartupdate')
  },
  methods: {
    initToasts () {
      if (this.globalToasts && !this.globalToastsShouldRender()) {
        this.globalToasts.forEach((toast) => {
          if (toast.stickWhenScrolling) {
            this.stickyToasts.push(toast)
          } else {
            this.toasts.push(toast)
          }
        })
      }
      if (this.pageToasts) {
        this.pageToasts.forEach((toast) => {
          if (toast.stickWhenScrolling) {
            this.stickyToasts.push(toast)
          } else {
            this.toasts.push(toast)
          }
        })
      }
    },
    globalToastsShouldRender () {
      if (this.component === 'storyblok') {
        const page = this.getStoryById(this.id)
        return page?.content?.disableGlobalToasts
      }
      return false
    },
    initPage () {
      if (this.data?.name) {
        this.$store.commit('page/setCurrentPageName', this.data.name)
      }
    },
  },
  head () {
    return {
      htmlAttrs: {
        lang: this.currentLanguageCode,
      },
    }
  },
}
</script>
